/* eslint-disable react/prop-types */

import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import ScreenHeight from "~components/ScreenHeight.jsx";
import { useTimeout } from "~utils/hooks";

const IndexPage = ({ data, location }) => {
  const { isDesktop } = useContext(DocumentContext);

  const [rendered, setRendered] = useState(false);

  //

  const cms = data.sanityIndex;

  useTimeout(() => {
    setRendered(true);
  }, 1500);

  //

  return (
    <>
      <SEO
        customTitle={cms?.title || ``}
        customDescription={cms?.seoDescription || ``}
        customKeywords={cms?.seoKeywords || ``}
        path={location.pathname}
      />

      <Layout
        styles={[
          css`
            min-height: 99vh;
          `,
          tw`bg-off-white`
        ]}
      >
        <ScreenHeight>
          <Grid styles={[tw`h-full`]}>
            <div
              css={[
                css`
                  ${A.Keyframes(
                    `appearDown`,
                    `1s ${A.EASING_CUBIC} forwards`,
                    `0.75s`
                  )}

                  padding-left: ${isDesktop() ? `3vw` : `0.25rem`};
                `,
                tw`col-span-6 md:col-span-3 col-start-7 md:col-start-9 flex items-end text-grey`
              ]}
            >
              <T.Body font="2">{cms.intro}</T.Body>
            </div>

            <div
              css={[
                css`
                  transition: opacity 1s ${A.EASING_CUBIC},
                    transform 1s ${A.EASING_CUBIC};

                  opacity: ${rendered ? `1` : `0`};
                  transform: translate3d(0, ${rendered ? `0` : `2vw`}, 0);
                `,
                tw`col-span-12 h-full flex items-end pb-6 md:pb-12`
              ]}
            >
              <h1
                css={[
                  tw`w-full relative block flex items-center justify-center text-center`
                ]}
              >
                <span
                  css={[
                    css`
                      font-size: 18.5vw !important;
                      line-height: 1 !important;
                    `,
                    tw`block text-h1 text-blue-black`
                  ]}
                >
                  Merus
                </span>
                <span
                  css={[
                    css`
                      font-size: 18.5vw !important;
                      font-weight: 200;
                      line-height: 1 !important;
                    `,
                    tw`block text-h1 text-blue-black`
                  ]}
                >
                  Capital
                </span>
              </h1>
            </div>
          </Grid>
        </ScreenHeight>

        {rendered && <Footer />}
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    sanityIndex {
      title
      intro
      seoDescription
      seoKeywords
    }
  }
`;
